import { Helmet } from "react-helmet";
import { Layout } from '../components/Layout/Layout';
import { TermsComponent } from '../components/Terms/Terms';

function Terms() {
  return (
    <Layout>
        <Helmet>
          <title>Terms • Jibrel</title>
        </Helmet>
        <TermsComponent />
    </Layout>
  );
}

export default Terms;