import { Layout } from '../components/Layout/Layout';
import { Landing } from '../components/Landing/Landing';

function Home() {
  return (
    <Layout>
      <Landing />       
    </Layout>
  );
}

export default Home;