import React, { useState }  from 'react';
import Logo from '../../assets/images/Logo.svg';

import { NavbarWrapper, NavbarLogoWrapper, NavbarLogo, NavbarLinksWrapper, NavbarLinks, NavbarLink } from './styles/NavbarComponents';

export function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div className="container navbar-container">
        <NavbarWrapper>
            <NavbarLogoWrapper>
              <NavbarLogo href="/">
                <img src={Logo} alt="Logo"/>
              </NavbarLogo>
            </NavbarLogoWrapper>
            <div id="navbar-icon" onClick={()=>setMenuOpen(!menuOpen)}><span></span><span></span><span></span></div>
            <NavbarLinksWrapper >
                <NavbarLinks toggle={menuOpen}>
                    <NavbarLink target="_blank" href={process.env.REACT_APP_CAREERS_URL}>
                      <span data-content="CAREERS"></span>
                      CAREERS
                    </NavbarLink>
                    <NavbarLink target="_blank" href={process.env.REACT_APP_BLOG_URL} unique>
                      BLOG
                    </NavbarLink>
                </NavbarLinks>
            </NavbarLinksWrapper>
        </NavbarWrapper>
    </div>
  );
}
