import React, { useState } from 'react';
import Redirect from '../../assets/images/Redirect.svg';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { LandingCardWrapper, LandingCardContent, LandingCardTitle, LandingCardDescription } from './styles/LandingComponents';


export function LandingCard({ title, description, hoverTitle, background, hoverBackground, width, link }) {
  const [hovered, setHovered] = useState(false);
  return (
    <LandingCardWrapper 
            background={background} 
            width={width}
            hoverBackground={hoverBackground}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}>
                <SwitchTransition>
                    <CSSTransition
                        key={hovered ? 'hovered' : 'notHovered'}
                        addEndListener={(node, done) =>
                            node.addEventListener('transitionend', done, false)
                        }
                        classNames='fade'
                    >
                        {!hovered ? (
                            <LandingCardContent>
                                <LandingCardTitle>
                                    {title}
                                </LandingCardTitle>
                                <LandingCardDescription>
                                    {description}
                                </LandingCardDescription>
                            </LandingCardContent>
                        ) : (
                            <LandingCardContent hovered>
                                <LandingCardTitle>
                                    <a href={link} target="_blank" rel="noreferrer">{hoverTitle} <img src={Redirect} alt="Redirect"/></a>
                                </LandingCardTitle>
                            </LandingCardContent>
                        )}
                    </CSSTransition>
                </SwitchTransition>
        
    </LandingCardWrapper>
  );
}