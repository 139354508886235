import { NotFoundWrapper, NotFoundImgWrapper, NotfoundImg, NotFoundTextWrapper, NotFoundTitle, NotFoundText, NotFoundRedirect } from './styles/NotFoundComponents';
import Img from '../../assets/images/404.png';


export function NotFoundComponent() {
  return (
    <NotFoundWrapper className="container">
      <NotFoundImgWrapper>
        <NotfoundImg src={Img} alt="Not Found" />
      </NotFoundImgWrapper>
      <NotFoundTextWrapper>
        <NotFoundTitle>It Looks Like You’re Lost</NotFoundTitle>
        <NotFoundText>This page is missing or you assembled the link incorrectly.</NotFoundText>
      </NotFoundTextWrapper>
      <NotFoundRedirect href="/">BACK TO MAIN</NotFoundRedirect>
    </NotFoundWrapper>
  );
}