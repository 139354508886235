import { Helmet } from "react-helmet";
import { Layout } from '../components/Layout/Layout';
import { NotFoundComponent } from '../components/NotFound/NotFound';

function NotFound() {
  return (
    <Layout>
        <Helmet>
          <title>404 Not Found • Jibrel</title>
        </Helmet>
        <NotFoundComponent />
    </Layout>
  );
}

export default NotFound;