import React from 'react';
import { LayoutWrapper } from './styles/LayoutComponents';
import { Navbar } from '../Navbar/Navbar';
import { Footer } from '../Footer/Footer';

export function Layout({ children }) {
  return (
    <LayoutWrapper>
      <Navbar />
        {children}
      <Footer />
    </LayoutWrapper>
  );
}