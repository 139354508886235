import styled from 'styled-components';

const LandingContentWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    @media (max-width: 992px) {
        flex-direction: column;
    }
    @media (max-width: 1200px){
        flex-direction: column;
    }
`

const LandingLeftContent = styled.div`
    //max-height: 400px;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 1200px){
        max-height: 100%;
        text-align: center;
    }
`


const LandingLeftTitle = styled.h2`
    max-width: 392px;
    width: 100%;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 72px;
    color: #464646;
    @media (max-width: 992px){
        font-size: 37px;
        line-height: 54px;
    }
`


const LandingLeftDescription = styled.h2`
    max-width: 379px;
    width: 100%;
    font-family: Roboto Mono;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    text-align: justify;
    line-height: 40px;
    color: #7F8497;
    @media (max-width: 992px){
        text-align: unset;
    }
`

const LandingLeftNote = styled.h2`
    width: 379px;
    height: 46px;
    box-sizing: border-box;
    position: relative;
    margin-top: 32px;
    padding: 9px 24px;
    background: #E5EFFF;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #2A79FF;
    transition: 300ms;
    text-decoration: none;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    @keyframes shake{
        0% {
            transform: rotate(-4deg);
        }
        50% {
            transform: rotate(4deg);
        }
        100% {
            transform: rotate(-4deg);
        }
    }
    i{
        position: absolute;
        display: block;
        left: 50%;
        top: 0;
        width: 5px;
        height: 10px;
        background: red;
        opacity: 0;
    }
    :hover{
        background: #DDEAFE;
        & > span:first-child{
            h2{
                animation: ${props => !props.animated ? "shake 150ms linear 2" : ""};
            }
        }
    }
    :active{
        background: #D6E6FE;
    }

    & > span:first-child{
        margin-right: 19px;
        position: relative;
        h2{
            position: relative;
        }
        
    }
    & > span:last-child{
        margin-left: 12px; 
        position: relative;
        left: 0;  
        font-weight: bold;
        color: #2E2E2E;
    }
    h2{
        position: relative;
    }
    
    @media (max-width: 992px){
        width: 100%;
        text-align: unset;
        font-size :13px;
        margin: 32px auto;
        & > span:first-child{
            margin-right: 7px;
        }
        & > span:last-child{
            margin-left: 7px;   
        }
    }

`


const LandingRightContent = styled.div`
    position: relative;
`


const UpperRightContent = styled.div`
    position: relative;
`
const LowerRightContent = styled.div`
    display: flex;
    justify-content: space-between;
    @media (max-width: 992px) {
        flex-direction: column;
    }
    @media (max-width: 1200px){
        flex-direction: column;
    }
`

const LandingCardWrapper = styled.div`
    width: ${({width}) => width};
    min-height: 167px;
    margin-top: 22px;
    height: 100%;
    padding: 29px 33px 24px 29px;
    box-sizing: border-box;
    background: ${({background}) => background};
    box-shadow: 0px 4px 4px rgba(127, 132, 151, 0.25);
    border-radius: 12px;
    &:hover{
        background: ${({hoverBackground}) => hoverBackground};
    }
    @media (max-width: 992px) {
        width: 100%;
        margin: 25px auto;
    }
    @media (max-width: 1200px){
        width: 100%;
    }
`
const LandingCardContent = styled.div`
    position: relative;
    ${({hovered}) => hovered && `
        float: right;
    `}
`



const LandingCardTitle = styled.h2`
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    color: rgba(255, 255, 255, 0.9);
    & > a{
        display: flex;
        color: #FFFFFF;
        text-decoration: none;
        & > img{
            margin-left: 9px;
        }
    }
`

const LandingCardDescription = styled.h2`
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    margin-top: 15px;
    color: rgba(255, 255, 255, 0.7);
`


export { 
    LandingContentWrapper, 
    LandingLeftContent, 
    LandingLeftTitle, 
    LandingLeftDescription, 
    LandingLeftNote, 
    LandingRightContent,
    UpperRightContent,
    LowerRightContent,
    LandingCardWrapper,
    LandingCardContent, 
    LandingCardTitle, 
    LandingCardDescription
};

