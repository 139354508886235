import React from 'react';
import twitter from '../../assets/images/twitter.svg';
import Discord from '../../assets/images/discord.svg';
import Medium from '../../assets/images/medium.svg';
import Linkedin from '../../assets/images/linkedin.svg';

import { FooterWrapper, FooterContent, FooterLeft, FooterLinks, FooterLink, FooterRight, FooterIcons, FooterIcon } from './styles/FooterComponents';


export function Footer() {

    return (
    <FooterWrapper className="container content-container">
        <FooterContent>
            <FooterLeft>
                <FooterLinks>
                    <FooterLink target="_blank"  href='/privacy'>
                        Privacy
                    </FooterLink>
                    <FooterLink target="_blank" href='/terms'>
                        Terms
                    </FooterLink>
                    <FooterLink target="_blank" href={process.env.REACT_APP_CAREERS_URL}>
                        Careers
                    </FooterLink>
                    <FooterLink target="_blank" href="mailto: support@jibrel.network">
                        Contact
                    </FooterLink>
                </FooterLinks>
            </FooterLeft>
            <FooterRight>                              
                <FooterIcons>
                    <FooterIcon target="_blank" href={process.env.REACT_APP_TWITTER_URL}>
                        <img src={twitter} alt="Twitter"/>
                    </FooterIcon>
                    <FooterIcon target="_blank" href={process.env.REACT_APP_LINKEDIN_URL}>
                        <img src={Linkedin} alt="Linkedin"/>
                    </FooterIcon>
                    <FooterIcon target="_blank" href={process.env.REACT_APP_DISCORD_URL}>
                        <img src={Discord} alt="Discord"/>
                    </FooterIcon>
                    <FooterIcon target="_blank" href={process.env.REACT_APP_MEDIUM_URL}>
                        <img src={Medium} alt="Medium"/>
                    </FooterIcon>
                </FooterIcons>
            </FooterRight>
        </FooterContent>
    </FooterWrapper>
  );
}