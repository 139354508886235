import styled from 'styled-components';


const PrivacyTermsWrapper = styled.div`

`
const PrivacyTermsContent = styled.div`
    width: 66.66%;
    @media (max-width: 992px){
        width: 100%;
    }
`

const PrivacyTermsSection = styled.div`
    display: flex;
    color: #081e39; 
    flex-direction: column;
    @media (max-width: 992px){

    }
`

const PrivacyTermsSectionTitle = styled.h2`
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    margin: 0 0 16px;
`
const PrivacyTermsSubTitle = styled.h2`
    font-weight: 800;
    font-size: 19px;
    line-height: 29px;
    margin: 0 0 16px;
`

const PrivacyTermsSectionText = styled.h2`
    margin: 0 0 16px;  
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    a{
        text-decoration: none;
    }
    a:hover{
        text-decoration: underline;
    }
`
const PagesTitle = styled.h2`
    font-weight: 900;
    margin: 64px 0 24px;
    font-size: 64px;
    line-height: 76px;
    color: #081e39;
`

const Table = styled.table`
    margin: 50px 0;
    border: 1px solid #081e39;
`
const TableHead = styled.th`
    padding: 5px;
    color: #081e39;
    border: 1px solid #081e39;
`
const TableData = styled.td`
    border: 1px solid #081e39;
    padding: 5px 20px;
`



export { PagesTitle, PrivacyTermsWrapper, PrivacyTermsContent, PrivacyTermsSectionTitle, PrivacyTermsSubTitle, PrivacyTermsSectionText, PrivacyTermsSection, Table, TableHead, TableData  };