import React, { useState } from 'react';
import { LandingContentWrapper, LandingLeftContent, LandingLeftTitle, LandingLeftDescription, LandingLeftNote, LandingRightContent,
  UpperRightContent,
  LowerRightContent
} from './styles/LandingComponents';
import { LandingCard } from './LandingCard';
import confetti from 'canvas-confetti';
export function Landing() {

  const [animated, setAnimated] = useState(false);
  const createConfetti = () =>{
    if(!animated){
      let canvas = document.getElementById('canvas');
      canvas.confetti = canvas.confetti || confetti.create(canvas, { resize: true });
      canvas.confetti({
        angle: 55,
        particleCount: 100,
        spread: 10,
        startVelocity: 40,
        scalar: 0.7,
        origin: { y: 1, x: 0 }
      });
      setTimeout(()=>{
        setAnimated(true);
      }, 200)
    }
  }

  return (
    <LandingContentWrapper className="container content-container">

      <LandingLeftContent>
        <LandingLeftTitle>
          We’re helping build a decentralized future.
        </LandingLeftTitle>
        <LandingLeftDescription>
          Jibrel is an open-source web3 development company based in Canton of Zug, Switzerland
        </LandingLeftDescription>
        <LandingLeftNote as="a" href={process.env.REACT_APP_DEFI_URL} target="_blank" animated={animated} onMouseEnter={createConfetti}>
          <span>
           <h2 animated={animated}>🎉 </h2>
           <canvas id="canvas" width="500" height="900"></canvas>
          </span>Tranche is live!<span>Get started!</span>  
        </LandingLeftNote>
      </LandingLeftContent>

      <LandingRightContent>
        
        <UpperRightContent>
          <LandingCard 
            title="decentralized finance" 
            hoverTitle="tranche.finance"
            description="Plug-and-Play Finance. tranche is a decentralized peer-to-peer lending and loan securitization platform. Connect your wallet and join the defi revolution."
            background="linear-gradient(92.41deg, #512BBD 2.06%, #446DFC 100%)" 
            hoverBackground="#4D28B6"
            width="650px"
            link={process.env.REACT_APP_DEFI_URL}
          />
        </UpperRightContent>
        <LowerRightContent>
          <LandingCard 
            title="asset tokenization" 
            hoverTitle="tokenize"
            description="Discover tokenized equities, regulated under the Abu Dhabi Global Markets (ADGM) RegLab"
            background="linear-gradient(92.41deg, #FA2B4E 2.06%, #FD9E47 100%)" 
            hoverBackground="#FA2B4E"
            width="390px"
            link={process.env.REACT_APP_TOKENIZE_URL}
          />
          <LandingCard 
            title="web3 piping"
            hoverTitle="GitHub"
            description="Build infrastructure for the internet of money."
            background="linear-gradient(100.48deg, #3681FF 17.64%, #25CFC5 108.65%)" 
            hoverBackground="#3681FF"
            width="240px"
            link={process.env.REACT_APP_GITHUB_URL}
          />
        </LowerRightContent>

      </LandingRightContent>


    </LandingContentWrapper>
  );
}