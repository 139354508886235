import styled from 'styled-components';

const LayoutWrapper = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 992px) {
        height: auto;
    }
    @media (max-height: 835px) and (max-width: 1200px) {
        height: auto;
    }
`

export { LayoutWrapper  }