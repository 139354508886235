import styled from 'styled-components';


const FooterWrapper = styled.div`
    position: relative;
`
const FooterContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 36px 0;
    @media (max-width: 992px) {
        flex-direction: column;
    }
`
const FooterLeft = styled.div`
    max-width: 249px;
    width: 100%;
    @media (max-width: 992px) {
        margin: 15px auto;
    }
    
`
const FooterLinks = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const FooterLink = styled.a`
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    color: #ADCDFF;
    text-decoration: none;
    :hover{
        color: #94BDFF;
    }
    @media (max-width: 992px) {
        margin: 0 7px;
    }
    
`
const FooterRight = styled.div`
    max-width: 180px;
    width: 100%;
    @media (max-width: 992px) {
        margin: 15px auto;
    }
`
const FooterIcons = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: end;
`
const FooterIcon = styled.a`
    position: relative;
    img{
        width: 20px;
        :hover{
            filter: brightness(1.2);
        }
    }
`


export { FooterWrapper, FooterContent, FooterLeft, FooterLinks, FooterLink, FooterRight, FooterIcons, FooterIcon }