import styled from 'styled-components';


const NavbarWrapper = styled.div`
    min-height: 50px;
    height: 100%;
    width: 100%;
    padding: 36px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 992px){
        flex-wrap: wrap;
    }
`
const NavbarLogoWrapper = styled.div`
    position: relative;
`
const NavbarLogo = styled.a`
    position: relative;
`
const NavbarLinksWrapper = styled.div`
    @media (max-width: 992px){
        flex-basis: 100%;
        margin-left: auto;
        padding-bottom: 25px;
    }

`
const NavbarLinks = styled.div`
    @media (max-width: 992px){
        display: flex;
        flex-direction: column;
        text-align: center;
        visibility: ${({toggle}) => toggle ? "visible" : "hidden"}
    }
`
const NavbarLink = styled.a`
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: rgb(70, 70, 70);
    opacity: 0.9;
    margin-left: 42px;
    text-decoration: none;
    transition: color 100ms ease;
    :hover{
        color: #156CFF;
    }
    ${({ unique }) => unique && `
        background: #E5EFFF;
        border-radius: 30px;  
        padding: 14px 34px;
        text-align: center;
        color: rgba(21, 108, 255, 0.9);
        transition: background 100ms ease;
        :hover{
            background: #156CFF;
            color: #ffffff;
        }
    `}
    
    
    @media (max-width: 992px){
        margin-left: 0;
        margin-top: 10px;
    }
`

export { NavbarWrapper, NavbarLogoWrapper, NavbarLogo, NavbarLinksWrapper, NavbarLinks, NavbarLink };