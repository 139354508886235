import { Helmet } from "react-helmet";
import { Layout } from '../components/Layout/Layout';
import { PrivacyComponent } from '../components/Privacy/Privacy';

function Privacy() {
  return (
    <Layout>
        <Helmet>
          <title>Privacy • Jibrel</title>
        </Helmet>
        <PrivacyComponent />
    </Layout>
  );
}

export default Privacy;