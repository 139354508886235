import styled from 'styled-components';

const NotFoundWrapper = styled.div`
    position: relative;  
`
const NotFoundImgWrapper = styled.div`
    position: relative;
`
const NotfoundImg = styled.img`   
    width: 100%;
`
const NotFoundTextWrapper = styled.div`   
    position: relative;
    color: #081e39;
`
const NotFoundTitle = styled.h2`   
    font-weight: 800;
    font-size: 64px;
    line-height: 76px;
    margin: 64px 0 0;
    text-align: center;
    @media (max-width: 992px){
        width: 100%;
        font-size: 40px;
        line-height: 50px;
    }
`
const NotFoundText = styled.h2`   
    width: 50%;
    font-weight: 400;
    font-size: 24px;
    line-height: 34px;
    margin: 64px auto 0 auto;
    text-align: center;
    padding-left: 12px;
    padding-right: 12px;
    box-sizing: border-box;
    @media (max-width: 992px){
        width: 100%;
    }
`
const NotFoundRedirect = styled.a`  
    width: 33.33333%; 
    background: #003dc6;
    border-color: #003dc6;
    color: #fff;
    margin: 64px auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    border-width: 4px;
    border-style: solid;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    padding: 19px 12px 18px;
    border-radius: 32px;
    transition-property: background, border-color, color;
    transition-duration: 0.15s;
    transition-timing-function: ease-in-out;
    transition-delay: unset;
    :hover{
        background: #ffffff;
        color: #003dc6;
    }
    @media (max-width: 992px){
        width: 100%;
    }
`
export { NotFoundWrapper, NotFoundImgWrapper, NotfoundImg, NotFoundTextWrapper, NotFoundTitle, NotFoundText, NotFoundRedirect };