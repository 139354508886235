import React from 'react';
import { GlobalStyle } from './global';
import Home from './pages/Home';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import NotFound from './pages/NotFound';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';

function App() {
  return (
    <div className="App">
       <GlobalStyle />
       <Router>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/privacy' component={Privacy} />
          <Route exact path='/terms' component={Terms} />
          <Route path='*' exact component={NotFound} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;

