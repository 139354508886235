import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body{
    background-color: #FFFFFF;
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden;
  }

  h2{
    margin: 0;
  }

  .container{
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  #navbar-icon{
    margin-left: auto;
    display: none;
    margin-top: -5px;
  }
  #navbar-icon span{
    height: 2px;
    margin: 4px 0;
    display: block;
    background: rgba(70, 70, 70, 0.9);
    width: 23px;
  }
  @media (max-width: 992px){
    #navbar-icon{
        display: block;
    }
  }
  canvas{
    position: absolute; 
    bottom: 0px;
    left: 0px; 
    pointer-events: none; 
    z-index: 1000;
  }

  @media (min-width: 768px){
    .navbar-container{
      //width: 920px;
    }
    .content-container{
      width: 650px;
    }
  }

  @media (min-width: 992px){
    .navbar-container{
      width: 973px;
    }
    .content-container{
     width: 776px; 
    }
  }
  @media (min-width: 1200px){
    .navbar-container{
      width: 1276px;
    }
    .content-container{
      width: 1092px;
    }
  }
  
  @media (max-width: 992px) {
    .navbar-container{
      margin-right: 0;
      margin-left: 0;
    }
  }
 

  @media only screen and (max-width: 700px) {
    
  }

  .fade-enter{
    opacity: 0;
  }
  .fade-exit{
    opacity: 1;
  }
  .fade-enter-active{
    opacity: 1;
  }
  .fade-exit-active{
    opacity: 0;
  }
  .fade-enter-active,
  .fade-exit-active{
    transition: opacity 200ms;
  }

`;

export { GlobalStyle };